import { render, staticRenderFns } from "./Redirect.vue?vue&type=template&id=e223e766&"
import script from "./Redirect.vue?vue&type=script&lang=js&"
export * from "./Redirect.vue?vue&type=script&lang=js&"
import style0 from "./Redirect.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports